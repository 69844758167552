.swal2-loader {
    display: none;
    align-items: center;
    justify-content: center;
    width: 2.2em;
    height: 2.2em;
    margin: 0 1.875em;
    animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
    
    border-radius: 100%;
}

h2#swal2-title {
    color: #93c01f;
    font-size: 24px;
}
button.swal2-confirm.swal2-styled {
    order: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: red;
    color: #fff;
    font-size: 14px;
    width: 300px;
}

div#swal2-html-container {
    font-size: 14px;
    color: #5e5c5c;
}

div.swal2-icon.swal2-error {
    border-color: red;
    color: red;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    display: block;
    position: absolute;
    top: 2.3125em;
    width: 2.9375em;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: red !important;
}

