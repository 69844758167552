.QR-container {
  margin: auto;
  width: 100%;
  margin-top: -125px;
  max-width: 406px;
  background-color: #ffffff !important;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  border: 1px solid #60bd37;
  padding: 10px;  
  position: relative;
  min-height: 600px;
}

.top-content {
  background-color: #7fbd2c;
  padding: 12px;
}

.QR-container::after {
  content: "";
  background-image: url("./../../assets/images/bottom-shadow.png");
  height: 26px;
  background-size: 100%;
  position: absolute;
  bottom: -26px;
  width: 100%;
  left: 0;
  background-repeat: no-repeat;
}

.QRamount p {
  font-size: 42px;
}

.QRamount span {
  font-size: 28px;
}

.QRusd p {
  font-size: 24px;
  margin: 0 0 10px !important;
}

.QRconvert p {
  font-size: 16px;
}

.QRcode-container {
  background-color: white;
  color: black;

}

.depositadd {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 12px;
  color: gray;
  width: 100%;
}

.depositadd span{
  font-weight: bold;
}
.InvoiceDetails{
  margin-top: 5px;
  font-size: 12px;
  text-align: initial;
  color: gray;
}
.InvoiceDetails span{
  cursor: pointer;
  font-weight: bold;
  text-align: initial;
  margin-left: 42px;
}

.copied {
  color: #7fbd2c;
  font-size: 10px;
  font-weight: bold;
}

.refresh-button {
  height: 55px;
  width: 98%;
  margin-bottom: 5px;
  margin-top: 5px;
  border: 0;
  background-color: white;
  border: 1px solid;
  border-color: #7fbd2c !important;
  color: #7fbd2c !important;
}

.cancel-button {
  height: 55px;
  width: 98%;
  border: 0;
  border: 1px solid;
  background-color: #7fbd2c !important;
  color: #fff;
}

.QRcode-container .info-text p {
  color: #7fbd2c;
  padding-top: 10px;
}

.payto {
  text-transform: uppercase;
  font-weight: bold;
}

.paytoname {
  text-transform: uppercase;
  color: #f6ff00;
}
