/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (min-width:1025px) and (max-width:1340px) {
  html { 
    zoom: 85%;
    -ms-zoom: 0.85;
    -webkit-zoom: 0.85;
  } 
  body { 
    zoom: 0.85;        
  }
  .container { width: 1200px; }

  .footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
}
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.loader{
  width: 65px;
  left: 50%;
  top: 50%;
  height: 65px;
  -webkit-animation: spin 2s linear infinite; 
  animation: spin 2s linear infinite;
  position:absolute;
  z-index:9999;

}

/*Loader*/

.modalloading {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,.5);
}

.modalloading-inner {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #d1cbcb;
}

body.loading {
  overflow: auto;
}

  body.loading .modalloading {
      display: block;
  }

.loader {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -50px;
  overflow: hidden;
  position: absolute;
  background-image: url("./../assets/images/loader.gif");
  background-repeat: no-repeat;
  background-position: center;
}

canvas {
  position: absolute;
  z-index: -1;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: #5fc742;
}

.counter {
  width: 100%;
  line-height: 100px;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.wait-txt {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 50px;
  margin-left: -45px;
  color: #683b11;
  font-size: 16px;
  font-weight: bold;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}