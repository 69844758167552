.header-container {
  background-color: #7fbd2c !important;
  display: flex;
  justify-content: space-around;
  height: 77px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.headLogo {
  margin-top: 0;
  color: white;
  font-size: 32px;
  display: flex;
  align-items: center;
}

.headLogo a {
  display: inline-flex;
}

.headtext {
  width: 121px;
  margin-top: 12px;
  height: 38px;
  border-radius: 87;
  border-radius: 50px;
  background-color: #7fbd2c !important;
  color: white;
  border-color: 1px solid white;
  border: 1px solid white;
  text-align: center;
}
@media only screen and (max-width:768px) {
  .header-container .container {
    width: 100%;
  }

  .header-container .row {
    display: flex;
    justify-content: space-between;
  }

  .headLogo img {
    max-width: 200px;
  }
}
@media only screen and (max-width:480px) {
  .headLogo img {
    max-width: 180px;
  }
}
