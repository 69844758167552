.footer-container {
  background-color: #f4f3f3;

  margin-top: 20px;
  color: #626262;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  padding-top: 15px;
}

.footHead {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}

.footHead p {
  color: #626262;
  font-size: 14px;
  line-height: 1.6em;

}

.footerInfo p {
  color: #626262;
  font-size: 14px;
  line-height: 1.6em;
  text-align: center;
  text-align: center;
}

.footerInfo a {
  color: #626262;
  text-decoration: none;
}

.footText {
  padding-top: 0;
  text-align: center;
  margin: 0;
  color: #626262;
  font-size: 14px;
  line-height: 27px;
}

.copysub {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.footText a {
  color: #626262;
  text-decoration: none;
}

.footer-section,
.socail-link {
  align-items: center;
}

.footer-section {
  justify-content: center;
}

.socail-link a {
  display: inline-block;
  margin-left: 10px;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width:1023px) {
  .footer-section {
    flex-wrap: wrap;
  }

  .copysub {
    display: block;
    text-align: center;
  }
}
