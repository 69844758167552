.payment {
  margin: auto;
  width: 100%;
  max-width: 653px;
  height: 276px;
  background-color: #5fc742 !important;
  border-radius: 10px;
  color: white;
  margin-top: 90px;
  text-align: center;
  background-image: url("./../../assets/images/bannner-head.png");
  padding: 15px;
  background-repeat: no-repeat;
}

.payment p {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: 'Raleway', sans-serif;
}

.payment p:first-child {
  font-size: 24px;
  font-weight: 600;
}

.payment span {
  color: #f6ff00;
}

.menu li {
  display: inline-flex;
  padding-right: 25px;
  align-items: center;
}

.menu li:last-child {
  padding-right: 0;
}

.menu li:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #fff;
  display: inline-block;
  margin-right: 7px;
}

.block-container {
  margin: auto;
  width: 100%;
  max-width: 406px;
  background-color: #fff;
  border-radius: 10px;
  color: #282c34;
  margin-top: -125px;
  text-align: center;
  border: 1px solid #e5e5e5;
  padding: 15px;
  position: relative;
  min-height: 600px;
}

.block-container::after {
  content: "";
  background-image: url("./../../assets/images/bottom-shadow.png");
  height: 26px;
  background-size: 100%;
  position: absolute;
  bottom: -26px;
  width: 100%;
  left: 0;
  background-repeat: no-repeat;
}

.circleicon {
  font-size: 50px;
}

.payment-list p {
  font-size: 22px;
  font-family: "Ubuntu", sans-serif;
}

.payment-list strong {
  display: block;
  margin-top: 7px;
}

.blockselect {
  background-color: #f8f8f8;
  font-family: "Open Sans", sans-serif;
}

.blockselect > div,
.payment-enter .blockselect {
  font-size: 16px;
  border: 0;
  background-color: #f8f8f8;
}

.blockselect .css-1okebmr-indicatorSeparator {
  background-color: transparent;
}

.payment-details p {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  margin: 5px;
  color: #7c7c7c;
}

.css-tlfecz-indicatorContainer svg {
  display: none;
}

.css-tlfecz-indicatorContainer {
  position: relative;
}

.css-tlfecz-indicatorContainer::after {
  content: '';
  position: absolute;
  right: 0;
  top: 4px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #807f7f;
}

.client-logo {
  width: 10px;
  height: 100px;
  border-radius: 50%;
  margin: 5px auto;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-title {
  color: #7c7c7c;
  font-size:18px;
}


.client-logo img {
  width: 100px;
  height: 100px;
    border-radius: 50%;
}

.payment-details {
  margin-bottom: 25px;
}

.payment-enter {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #f8f8f8;
}

.payment-enter input {
  font-size: 42px;
  color: #7fbd2c;
  font-family: "Ubuntu", sans-serif;
  width: 65%;
  border: none;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  padding: 0 10px;
  background-color: #f8f8f8;
  height: auto;
  box-shadow: none;
}

.payment-enter input:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
}

.payment-enter select {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  width: 75%;
  color: #585757;
  border: 0;
  background-color: #f8f8f8;
}

.payment-msg textarea {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #ececec;
  height: 95px;
  border-radius: 4px;
  width: 100%;
}

.blockbutton {
  height: 48px;
  width: 100%;

  background-color: white;
  border-radius: 6px;
  border: 1px solid #7fbd2c !important;
  margin-top: 12px;
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 25px;
}
@media only screen and (max-width:768px) {
  .payment-content {
    padding: 10px;
  }

  .block-container {
    width: 90%;
  }

  .menu {
    padding: 0;
  }

  .payment {
    height: 330px;
    padding: 25px 15px;
  }
}

.required {
  border: 1px solid red !important;
}

.maxlimit{
  width: 100%;
  text-align: left;
  padding: 5px;
}

span.maxerror {
  color: red;
}

.blockbutton:hover {
  height: 48px;
  width: 100%;
  background-color: #7fbd2c !important;
  border-radius: 6px;
  border: 1px solid #7fbd2c !important;
  margin-top: 12px;
  color: #ffffff;
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 25px;
}

.css-qc6sy-singleValue {
  color: #7fbd2c  !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
}