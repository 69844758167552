.confirm-container {
  margin: auto;
  width: 100%;
  max-width: 406px;
  background-color: #7fbd2c !important;
  border-radius: 10px;
  color: #ffffff;

  text-align: center;
  border: 1px solid #e5e5e5;
  padding: 15px;
  position: relative;

}

.confirm-container::after {
  content: "";
  background-image: url("./../../assets/images/bottom-shadow.png");
  height: 26px;
  background-size: 100%;
  position: absolute;
  bottom: -26px;
  width: 100%;
  left: 0;
  background-repeat: no-repeat;
}

.confirm-tick {
  height: 68px;

}

.confirm-container-main {
  margin: auto;
  width: 100%;
  max-width: 406px;
  background-color: #ffffff !important;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  padding: 5px;
  position: relative;
  margin-top: -125px;
}

.transaction-success{
  display: flex;
  flex-direction:  column;
}
.transaction-success span {
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #f6ff28;
}

.sent-receive-details {
  border: 2px solid #e4ff7a;
  margin-top: 30px;
  padding: 0px 15px 15px;

}

.sent-receive-text{
  color: #e4ff7a;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-block;
  padding-left: 18px;
  padding-right: 18px;
  background: #7fbd2c;
  top: -14px;
  position: relative;
}

.pay-value > span:first-child {
    font-size: 55px;
}
.pay-value > .volume-trade {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.transaction-section .currency-value, #merchant-expect-total {
    font-size: 20px;
    font-family: 'ubuntu', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 0 10px;
}
.currency-value {
    font-size: 18px;
    font-family: 'ubuntu', sans-serif;
    font-weight: 400;
    word-break: break-word;
}

.volume-trade > span {
  font-size: 32px;
  margin-left: 13px;
}
.pay-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 45px;
  line-height: normal;
  word-break: break-word;
}

.amtFC {
  font-size: 62px;
}

.amtFC span {
  font-size: 30px;
}

.amt-convert p {
  font-size: 24px;
}

.pay-transaction-details {
  background-color: #7aa507;
  color: #ffffff;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.transId {
  text-align: initial;
  margin-left: 10px;
  font-size: 14px;
}

.transdate {
  text-align: initial;
  margin-left: 10px;
  font-size: 14px;
}

.trans-button {
  height: 55px;
  width: 98%;
  border: 0;
  border: 1px solid;
  background-color: #7fbd2c !important;
  color: #fff;
  margin-top: 65px;
}

.backhome-button {
  height: 55px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  border: 0;
  border-color: 1px solid red;
  background-color: white;
  border: 1px solid;
  border-color: #7fbd2c !important;
  color: #7fbd2c !important;
  font-weight: bold;
}

.transIdtitle {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.transIdvalue {
  font-size: 12px;
  font-weight: bold;
}
